import { base } from "../avatar.mjs";

export async function getSessionToken() {
    try {
        const response = await fetch(`${base}/api/ai/heygen/create-token/`);
      
          if(!response.ok) {
              throw new Error("Something went wrong while getting sesstion token");
          }
      
          const data = await response.json();
          const sessionToken = data.response.data.token;
          
          return sessionToken;
    } catch (error) {
        console.log(error);
        return { error: error.message };
    }
  }