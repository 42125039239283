import Splide from "@splidejs/splide";

export function initTeaserCardsSlider() {
    const teaserCardSliders = document.querySelectorAll(".dm-teaser-card-slider__slider");

    if(teaserCardSliders) {
        for (const slider of teaserCardSliders) {
            const inEditMode = slider.getAttribute("data-in-edit-mode");

            // Accessibility
            const prevArrowAriaLabel = slider.getAttribute("data-prev-aria-label");
            const nextArrowAriaLabel = slider.getAttribute("data-next-aria-label");
            const paginationAriaLabel = slider.getAttribute("data-pagination-aria-label");
            const paginationBulletAriaLabel = slider.getAttribute("data-pagination-bullet-aria-label");

            const teasrSlider = new Splide(slider, {
                arrows: false,
                type: inEditMode === "true" ? "slide" : "loop",
                padding: "10%",
                mediaQuery: "min",
                flickPower: 100,
                gap: "1.625rem",
                perMove: 1,
                breakpoints: {
                    "768": {
                        padding: 0,
                        perPage: 2
                    },
                    "1180": {
                        perPage: 3,
                    },
                    "1400": {
                        arrows: true
                    }
                },
                i18n: {
                    prev: prevArrowAriaLabel,
                    next: nextArrowAriaLabel,
                    first: nextArrowAriaLabel,
                    last: prevArrowAriaLabel,
                    select: paginationAriaLabel,
                    slideX: `${paginationBulletAriaLabel} %s`
                }
            });

            teasrSlider.on("mounted", () => {
                const slider = teasrSlider.Components.Slides.get();

                slider.forEach(slide => slide.slide.removeAttribute("role"));
            });

            teasrSlider.mount();
        }
    }
}