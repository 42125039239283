import { Splide } from "@splidejs/splide"

export function initProductSlider() {
    const productSliders = document.querySelectorAll(".dm-product-slider__slider");

    if (productSliders.length > 0) {
        for (const productSlidersItem of productSliders) {
            const prevArrows = productSlidersItem.querySelectorAll(".dm-product-card__arrow--prev");
            const nextArrows = productSlidersItem.querySelectorAll(".dm-product-card__arrow--next");
            const childCount = productSlidersItem.querySelectorAll(".splide__slide").length;
            const showPagination = childCount > 0 ? true : false;

            if (childCount === 1) {
                productSlidersItem.classList.add("single-slide");
            }

            const paginationAriaLabel = productSlidersItem.getAttribute("data-pagination-aria-label");
            const paginationBulletAriaLabel = productSlidersItem.getAttribute("data-pagination-bullet-aria-label");

            const productSlider = new Splide(productSlidersItem, {
                type: childCount > 1 ? "loop" : "slide",
                gap: "1rem",
                padding: "1rem",
                perMove: 1,
                arrows: false,
                pagination: showPagination,
                updateOnMove: true,
                mediaQuery: "min",
                breakpoints: {
                    640: {
                        padding: "16%"
                    },
                    1400: {
                        gap: "2rem"
                    },
                    1800: {
                        padding: "27.5%"
                    },
                },
                i18n: {
                    select: paginationAriaLabel,
                    slideX: `${paginationBulletAriaLabel} %s`
                }
            });

            productSlider.on("mounted", () => {
                const slider = productSlider.Components.Slides.get();

                slider.forEach(slide => slide.slide.removeAttribute("role"));
            });

            productSlider.mount();

            for (const prevArrow of prevArrows) {
                prevArrow.addEventListener("click", () => productSlider.go('-${i}'));
            }

            for (const nextArrow of nextArrows) {
                nextArrow.addEventListener("click", () => productSlider.go('+${i}'));
            }
        }
    }
}
