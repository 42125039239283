import { base } from "../avatar.mjs";

export async function closeSession(sessionInfo, mediaElement, room) {
    if (!sessionInfo) {
        return;
    }

    try {
        await fetch(`${base}/api/ai/heygen/close-session/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                session_id: sessionInfo.session_id
            }),
        });
        
        // Handle media stream removal
        room.on(LivekitClient.RoomEvent.TrackUnsubscribed, (track) => {
            const mediaTrack = track.mediaStreamTrack;
            if (mediaTrack) {
                mediaStream.removeTrack(mediaTrack);
            }
        });

        if (room) {
            room.disconnect();
        }
        
        sessionInfo = null;
        mediaElement.srcObject = null;
        room = null;
        
        
    } catch (error) {
        console.log(error);
        return { error: error.message };
    }   
}