export function toggleMobileMenu() {
    const header = document.querySelector(".dm-header");
    const mq = window.matchMedia("(min-width: 1280px)");

    if (header) {
        const documentEl = document.documentElement;
        const toggleMenuBtn = document.querySelector("[data-toggle-mebile-menu]");
        const subNavTriggers = header.querySelectorAll(".has-child [aria-controls]");
        const subNavTriggersDesktop = header.querySelectorAll(".nav-item > a");
        const navSlider = header.querySelector(".nav-slider");
        const backNavTriggers = header.querySelectorAll("[data-nav-to-lvl-1]");
        const headerCta = header.querySelector(".dm-header__additional .dm-btn");

       

        toggleMenuBtn.addEventListener("click", toggleMenu);

        // Toggle mobile menu
        function toggleMenu() {
            if (toggleMenuBtn.getAttribute("aria-expanded") === "false") {
                toggleMenuBtn.setAttribute("aria-expanded", "true");
                header.classList.add("active");
                documentEl.style.overflow = "hidden";
            } else {
                toggleMenuBtn.setAttribute("aria-expanded", "false");
                header.classList.remove("active");
                documentEl.removeAttribute("style");
                navSlider.classList.remove("lvl-2-active");
                navSlider.querySelector(".nav-lvl-2 .active").classList.remove("active");
            }
        }

        // Activate lvl-2 navigation
        for (const subNavTrigger of subNavTriggers) {
            const subNavTarget = subNavTrigger.getAttribute("aria-controls");

            subNavTrigger.addEventListener("click", (e) => {
                e.preventDefault();
                header.querySelector("#" + subNavTarget).classList.add("active");
                navSlider.classList.add("lvl-2-active");
            });
        }

        // Navigate back to lvl-1
        for (const backNavTrigger of backNavTriggers) {
            backNavTrigger.addEventListener("click", () => {
                backNavTrigger.closest("div").classList.remove("active");
                navSlider.classList.remove("lvl-2-active");
            });
        }

        // Desktop keyboard accessibility
        for (const subNavTrigger of subNavTriggersDesktop) {
            subNavTrigger.addEventListener("keydown", (e) => {
                const parent = subNavTrigger.closest(".has-child");

                if(parent) {
                    const subMenu = subNavTrigger.closest(".has-child").querySelector(".nav-child");
                    const subMenuLinks = subMenu.querySelectorAll("a");
    
                    if (e.key === "ArrowDown" || e.key === " ") {
                        e.preventDefault();
    
                        parent.setAttribute("aria-expanded", "true");
                        subMenu.setAttribute("aria-hidden", "false");
                        subMenuLinks.forEach(link => link.removeAttribute("tabindex"));
                    }
                }
            });

            subNavTrigger.addEventListener("focus", () => {
                handleKeyboardNavigation()
            });
        }

        if(headerCta) {
            headerCta.addEventListener("focus", () => {
                handleKeyboardNavigation();
            });
        }
       
        function handleKeyboardNavigation() {
            const prevActiveMenuItem = header.querySelector(".has-child[aria-expanded='true']");

                if (prevActiveMenuItem) {
                    const prevActiveSubMenu = prevActiveMenuItem.querySelector(".nav-child");
                    const subMenuLinks = prevActiveSubMenu.querySelectorAll("a");

                    prevActiveMenuItem.setAttribute("aria-expanded", "false");
                    prevActiveSubMenu.setAttribute("aria-hidden", "true");
                    subMenuLinks.forEach(link => link.setAttribute("tabindex", "-1"));
                }
        }
        // Desktop keyboard accessibility - END

        window.addEventListener("scroll", handleMenuBackground);

        document.addEventListener("DOMContentLoaded", handleMenuBackground);

        function handleMenuBackground() {
            const headerHeight = header.offsetHeight;

            if (window.scrollY >= headerHeight) {
                header.classList.add("scrolling");
            } else {
                header.classList.remove("scrolling");
            }
        }

        function handleOverflow(e) {
            if (e.matches) {
                toggleMenuBtn.setAttribute("aria-expanded", "false");

                setTimeout(() => {
                    header.classList.remove("active");
                    documentEl.removeAttribute("style");
                }, 310);
            }
        }

        mq.addEventListener("change", (e) => handleOverflow(e));

        handleOverflow(mq);

        // TRACK LANGUAGE SWITCH
        const langSwitch = document.querySelector(".language-switcher");

        if (langSwitch) {
            const langs = langSwitch.querySelectorAll("a");

            for (const lang of langs) {
                lang.addEventListener("click", () => {
                    const selectedLang = lang.getAttribute("data-lang");

                    window.dataLayer.push({
                        "event": "language_switch",
                        "lang": selectedLang,
                        "event_id": 1.3
                    });
                });
            }
        }
    }
}