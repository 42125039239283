// Connect WebSocket
export async function connectWebSocket(sessionInfo) {
    
    const params = new URLSearchParams({
        session_id: sessionInfo.session_id,
        session_token: sessionInfo.session_token
    });

    const wsUrl = `wss://${
        new URL("https://api.heygen.com").hostname
    }/v1/ws/streaming.chat?${params}`;

    webSocket = new WebSocket(wsUrl);

    // Handle WebSocket events
    // webSocket.addEventListener("message", (event) => {
    //     const eventData = JSON.parse(event.data);
    //     console.log("Raw WebSocket event:", eventData);
    // });
  }