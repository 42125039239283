import Splide from "@splidejs/splide";

export function initCasesSlider() {
    const dmCases = document.querySelectorAll(".dm-cases");

    if (dmCases) {
        for (const dmCase of dmCases) {
            const imageSliderEl = dmCase.querySelector(".dm-cases__images");
            const textSliderEl = dmCase.querySelector(".dm-cases__text");

            const _autoplay = imageSliderEl.dataset.autoplay;
            const _interval = +imageSliderEl.dataset.autoplayInterval;

            // accessibility
            const prevArrowAriaLabel = imageSliderEl.getAttribute("data-prev-aria-label");
            const nextArrowAriaLabel = imageSliderEl.getAttribute("data-next-aria-label");
            const paginationAriaLabel = imageSliderEl.getAttribute("data-pagination-aria-label");
            const paginationBulletAriaLabel = imageSliderEl.getAttribute("data-pagination-bullet-aria-label");
            const paginationAriaLabelText = textSliderEl.getAttribute("data-pagination-aria-label");
            const paginationBulletAriaLabelText = textSliderEl.getAttribute("data-pagination-bullet-aria-label");

            const imageSlider = new Splide(imageSliderEl, {
                arrows: false,
                pagination: false,
                flickPower: 100,
                type: "loop",
                gap: "1rem",
                autoplay: _autoplay === "true" ? true : false,
                interval: _interval ? _interval : 5000,
                mediaQuery: "min",
                breakpoints: {
                    1148: {
                        arrows: true,
                        pagination: true
                    }
                },
                i18n: {
                    prev: prevArrowAriaLabel,
                    next: nextArrowAriaLabel,
                    first: nextArrowAriaLabel,
                    last: prevArrowAriaLabel,
                    select: paginationAriaLabel,
                    slideX: `${paginationBulletAriaLabel} %s`
                }
            });

            const textSlider = new Splide(textSliderEl, {
                type: "fade",
                rewind: true,
                arrows: false,
                mediaQuery: "min",
                autoHeight: true,
                flickPower: 100,
                breakpoints: {
                    1148: {
                        pagination: false
                    }
                },
                i18n: {
                    select: paginationAriaLabelText,
                    slideX: `${paginationBulletAriaLabelText} %s`
                }
            });

            imageSlider.sync(textSlider);

            imageSlider.on("mounted", () => {
                const slider = imageSlider.Components.Slides.get();

                slider.forEach(slide => slide.slide.removeAttribute("role"));
            });

            textSlider.on("mounted", () => {
                const slider = textSlider.Components.Slides.get();

                slider.forEach(slide => slide.slide.removeAttribute("role"));
            });

            imageSlider.mount();
            textSlider.mount();
        }
    }
}