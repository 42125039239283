import Splide from "@splidejs/splide";

export function initBlogWallSlider() {
    const blogWallSliders = document.querySelectorAll(".dm-blog-post-wall__slider");

    if(blogWallSliders.length > 0) {
        for(const blogWallSlider of blogWallSliders) {
            const paginationAriaLabel = blogWallSlider.getAttribute("data-pagination-aria-label");
            const paginationBulletAriaLabel = blogWallSlider.getAttribute("data-pagination-bullet-aria-label");

            const blgwSlider = new Splide(blogWallSlider, {
                type: "loop",
                arrows: false,
                gap: "0.875rem",
                mediaQuery: "min",
                flickPower: 100,
                breakpoints: {
                    "768": {
                        perPage: 2
                    },
                    "1180": {
                        perPage: 3,
                        type: "slide"
                    }
                },
                i18n: {
                    select: paginationAriaLabel,
                    slideX: `${paginationBulletAriaLabel} %s`,
                    pageX: `${paginationBulletAriaLabel} %s`
                }
            });

            blgwSlider.mount();
    
            blgwSlider.on("resize", () => {
                blgwSlider.refresh();

                const slider = blgwSlider.Components.Slides.get();
                slider.forEach(slide => {
                    slide.slide.removeAttribute("role");

                    const specialSlideCircle = slide.slide.querySelector(".circle");
                    if(specialSlideCircle) specialSlideCircle.setAttribute("tabindex", "-1");
                });
            });
        }
    }
}