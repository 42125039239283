export function showPrompts(showPromptsButton, hidePromptsButton, form, campaignName) {
    showPromptsButton.classList.add("d-none");
    hidePromptsButton.classList.add("d-block");

    const buttonsToggleTimeout = setTimeout(() => {
        hidePromptsButton.classList.add("show-opacity");
        clearTimeout(buttonsToggleTimeout);
    }, 200);

    // Show all prompts with staggered animations
    const prompts = form.closest(".dm-ai-chat").querySelector(".chat-prompts-container");
    prompts.classList.add("show");
    window.dataLayer.push({
        "event": "select_content",
        "event_id": 1.12,
        "language": document.documentElement.lang,
        "campaign": campaignName
    });
}

export function hidePrompts(showPromptsButton, hidePromptsButton, form) {
    showPromptsButton.classList.remove("d-none");
    hidePromptsButton.classList.remove("show-opacity");
    hidePromptsButton.classList.remove("d-block");
    const prompts = form.closest(".dm-ai-chat").querySelector(".chat-prompts-container");
    prompts.classList.remove("show");
}

export function updateContent(context, selector, content) {
    const element = context.querySelector(selector);
    element.innerHTML = content;
}

export function showAvatarInputField(form) {
    if(form.classList.contains("d-none")) {
        form.classList.remove("d-none");
        form.classList.add("fade-in");
    }
}

export function sanitizeInput(input) {
    return input.replace(/(^[^\wäöüß]+|[^\wäöüß]+$|\b[^\wäöüß]+\b)/g, ' ').trim();
}

export function getClientHash() {
    const params = new URLSearchParams(window.location.search);
    const clientHash = params.get("ch")?.replace(/(^[^\wäöüß-]+|[^\wäöüß-]+$|\b[^\wäöüß-]+\b)/g, ' ').trim();
    return clientHash || "";
}