import { base } from "../avatar.mjs";

export async function sendTask(sessionInfo, promptValues) {
    try {
        // Send text to avatar to speak
        const heygenResponse = await fetch(`${base}/api/ai/heygen/send-task/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                session_id: sessionInfo.session_id,
                text: promptValues.response,
            }),
        });

        const heygenSpeakResponse = await heygenResponse.json();

        if (!heygenResponse.ok) {
            throw new Error(`${heygenSpeakResponse.detail}. Try again later.` || `HTTP error! status: ${heygenResponse.status}`);
        }

        return heygenSpeakResponse
    } catch (error) {
        console.log(error);
        return { error: error.message };
    }
}