import Splide from "@splidejs/splide";

export function initSocialSlider() {
    const socialSliders = document.querySelectorAll(".dm-social-slider");

    if (socialSliders) {
        for (const socialSlider of socialSliders) {
            const slideNo = socialSlider.querySelectorAll(".splide__slide").length;

            if (slideNo === 2) {
                socialSlider.classList.add("two-slides");
            }

            const paginationAriaLabel = socialSlider.getAttribute("data-pagination-aria-label");
            const paginationBulletAriaLabel = socialSlider.getAttribute("data-pagination-bullet-aria-label");

            const socialSlideEl = new Splide(socialSlider, {
                type: slideNo >= 2 ? "loop" : "slide",
                gap: "1.25rem",
                mediaQuery: "min",
                arrows: false,
                flickPower: 100,
                classes: {
                    pagination: 'splide__pagination splide__pagination--social-slider'
                },
                breakpoints: {
                    768: {
                        type: slideNo > 2 ? "loop" : "slide",
                        perPage: 2
                    },
                    992: {
                        perPage: 3
                    }
                },
                i18n: {
                    select: paginationAriaLabel,
                    slideX: `${paginationBulletAriaLabel} %s`,
                    pageX: `${paginationBulletAriaLabel} %s`
                }
            });

            socialSlideEl.mount();

            socialSlideEl.on("resize", () => {
                socialSlideEl.refresh();

                const slider = socialSlideEl.Components.Slides.get();

                slider.forEach(slide => slide.slide.removeAttribute("role"));
            });
        }
    }
}