import { base } from "../avatar.mjs";

export async function sendText(url, sessionInfo, hashIdentifier, inputText) {
    
    try {
        const response = await fetch(`${base}/${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                session_id: sessionInfo.session_id,
                text: inputText,
                identifier: hashIdentifier,
                language: document.documentElement.lang
            }),
        });
        
        const promptValues = await response.json();

        if (!response.ok) {
            throw new Error("Something went wrong when sending task");
        }

        return promptValues;
    } catch (error) {
        console.log(error);
        return { error: error.message };
    }
}