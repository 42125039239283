import { base } from "../avatar.mjs";

export async function fetchVideoLink(hashIdentifier) {
    try {
        const getVideoLink = await fetch(`${base}/api/ai/heygen/chat-ai/get-link/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                identifier: hashIdentifier
            }),
        });

        if(!getVideoLink.ok) {
            throw new Error(`Could not get video link`);
        }

        const getVideoLinkResponse = await getVideoLink.json();

        return getVideoLinkResponse;

    } catch (error) {
        console.log(error);
        return { error: error.message }
    }
}