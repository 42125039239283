import Splide from "@splidejs/splide";

export function initClientQuotesSlider() {
    const clientQuotesSliders = document.querySelectorAll(".dm-client-quotes__slider");
   
    if (clientQuotesSliders.length > 0) {
        for (const clientQuotesSlider of clientQuotesSliders) {
            const numberOfSlides = clientQuotesSlider.querySelectorAll(".splide__slide").length;

            // accessibility
            const prevArrowAriaLabel = clientQuotesSlider.getAttribute("data-prev-aria-label");
            const nextArrowAriaLabel = clientQuotesSlider.getAttribute("data-next-aria-label");
            const paginationAriaLabel = clientQuotesSlider.getAttribute("data-pagination-aria-label");
            const paginationBulletAriaLabel = clientQuotesSlider.getAttribute("data-pagination-bullet-aria-label");
        
            const quoteSlider = new Splide(clientQuotesSlider, {
                type: "loop",
                gap: "1.75rem",
                arrows: false,
                pagination: true,
                mediaQuery: "min",
                padding: "15%",
                updateOnMove: true,
                flickPower: 100,
                perMove: 1,
                clones: numberOfSlides,
                breakpoints: {
                    "768": {
                        gap: 0,
                        padding: "20%",
                    },
                    "1200": {
                        arrows: true
                    },
                    "1400": {
                        padding: "26.55%",
                    }
                },
                i18n: {
                    prev: prevArrowAriaLabel,
                    next: nextArrowAriaLabel,
                    first: nextArrowAriaLabel,
                    last: prevArrowAriaLabel,
                    select: paginationAriaLabel,
                    slideX: `${paginationBulletAriaLabel} %s`
                }
            });

            quoteSlider.on("mounted", () => {
                const slider = quoteSlider.Components.Slides.get();

                slider.forEach(slide => slide.slide.removeAttribute("role"));
            });

            quoteSlider.mount();
        }
    }
}