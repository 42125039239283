import { base } from "../avatar.mjs";

export async function startSession(sessionInfo) {

    try {
        const streamingResponse = await fetch(`${base}/api/ai/heygen/start-session/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                session_id: sessionInfo.session_id
            })
        });

        if(!streamingResponse.ok) {
            throw new Error("Something went wrong when starting streaming");
        }

        const data = await streamingResponse.json();
        
        return data;
    } catch (error) {
        console.log(error);
        return { error: error.message };
    }
}