import { getSessionToken } from "./getSessionToken.mjs";
import { base } from "../avatar.mjs";

export async function createSession(campaignName) {
    const sessionToken = await getSessionToken();

    try {
        const response = await fetch(`${base}/api/ai/heygen/new-session/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              campaign: campaignName
            })
        });
    
        if(!response.ok) {
            throw new Error("Something went wrong when getting session. Try again later.");
        }
    
        const sessionInfo = await response.json();
        sessionInfo.session_token = sessionToken;

        return sessionInfo;
    } catch (error) {
        console.log(error);
        return { error: error.message };
    }
}