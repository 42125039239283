export function initGlossaryAccordion() {
    const glossaryFilterButtons = document.querySelectorAll(".dm-glossary .dm-tag");

    if (glossaryFilterButtons.length > 0) {
        
        const glossaryAccordions = document.querySelectorAll(".dm-accordion");

        glossaryFilterButtons.forEach((button) => {
            const accordionId = button.dataset.filter;

            button.addEventListener("click", () => {
                glossaryFilterButtons.forEach((btn) => btn.classList.remove("active"));

                glossaryAccordions.forEach((accordion) => accordion.style.display = "none");

                const targetAccordion = Array.from(glossaryAccordions).find((accordion) => accordion.dataset.accordionId === accordionId);

                if (targetAccordion) {
                    targetAccordion.style.display = "block";
                }

                button.classList.add("active");

                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set('filter', accordionId);
                let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({ path: newurl }, '', newurl);
            });
        });

        highlightSelectedFilterOnPageRefresh()

        function highlightSelectedFilterOnPageRefresh() {
            // fix for when in edit mode
            if (window.location.href.includes("mgnlPreview")) return;

            // Add 'active' class to filter button on page refresh if filter is active
            const activeFilter = window.location.href.split("=")[1];
            const currentlyActiveButton = document.querySelector(`[data-filter].active`);

            if (typeof activeFilter !== "undefined") {
                const filterButton = document.querySelector(`[data-filter=${activeFilter}]`);

                if (currentlyActiveButton) currentlyActiveButton.classList.remove("active");
                if (filterButton) filterButton.classList.add("active");

                glossaryAccordions.forEach((accordion) => accordion.style.display = "none");

                const targetAccordion = Array.from(glossaryAccordions).find((accordion) => accordion.dataset.accordionId === activeFilter);

                if (targetAccordion) {
                    targetAccordion.style.display = "block";
                }
            }
        }
    }
}