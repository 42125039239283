import { hidePrompts, showPrompts, updateContent, sanitizeInput, getClientHash, showAvatarInputField } from "./avatar/helpers.mjs";
import { startSession } from "./avatar/startSession.mjs";
import { closeSession } from "./avatar/closeSession.mjs";
import { sendText } from "./avatar/sendText.mjs";
import "./avatar/livekit-client.mjs";
import { handleDownloadButtonClick, closeOverlay } from "./avatar/heroOverlay.mjs";
import { stopAvatar } from "./avatar/stopAvatar.mjs";
import { createSession } from "./avatar/createSession.mjs";
import { connectWebSocket } from "./avatar/connectWebSocket.mjs";
import { sendTask } from "./avatar/sendTask.mjs";
import { fetchVideoLink } from "./avatar/fetchVideoLink.mjs";

export const base = document.querySelector(".dm-ai-form") ? document.querySelector(".dm-ai-form").getAttribute("data-url") : "";

export async function avatar() {
    
    let pendingFormRequest = false;
    let initialPageLoad = true;
    const language = document.documentElement.lang;
    let sessionDetails = null;
    let room = null;
    let avatarMediaStream = null;

    const avatar = document.querySelector(".dm-hero--ai");

    if(avatar) {
        let apiUrl;
        const campaignName = avatar.getAttribute("data-campaign") || "";
        campaignName === "Unboxing2025" ? apiUrl = `api/ai/heygen/chat-ai/chat-ai-${campaignName.toLowerCase()}/` : apiUrl = `api/ai/heygen/chat-ai/chat-ai/`;
        const mediaElement = avatar.querySelector(".js-avatar-video");
        const form = avatar.querySelector(".dm-ai-form");
        const taskInput = form.querySelector(".js-task-input");
        const errorContainer = form.closest(".dm-ai-chat").querySelector(".chat-error");
        const loadingSpinner = avatar.querySelector(".loader-container");
        const playAvatarOverlay = avatar.querySelector(".dm-ai-chat__play-overlay");
        const submitButton = form.querySelector(".js-talk-button");
        const stopAvatarButton = form.closest(".dm-ai-chat").querySelector(".js-stop-avatar");
        const greetingVideo = avatar.querySelector(".js-greeting-video");
        const showPromptsButton = avatar.querySelector(".js-show-prompts");
        const hidePromptsButton = avatar.querySelector(".js-hide-prompts");
        const prompts = form.closest(".dm-ai-chat").querySelectorAll(".chat-prompt");
        const messageContainer = avatar.querySelector(".chat-message");
        const audio = form.closest(".dm-hero--ai").querySelector("audio");
        let inputText = "";

        // -------- Add overlay on page -------- //
        const template = document.querySelector("#ai-overlay").content.cloneNode(true);
        const body = document.querySelector("body");
        body.appendChild(template);

        // -------- Unique client identifier -------- //
        const hashIdentifier = getClientHash();

        // -------- Helper functions -------- //
        // Start session
        async function handleStartAvatarSession(e) {

            if(errorContainer.classList.contains("d-block")) errorContainer.classList.remove("d-block");
            loadingSpinner.classList.add("d-block");

            try {
                const sessionInfo = await createSession(campaignName, mediaElement);
                sessionDetails = sessionInfo;

                // Connect to LiveKit room
                // Create LiveKit Room
                room = new LivekitClient.Room({
                    adaptiveStream: true,
                    dynacast: true,
                    videoCaptureDefaults: {
                        resolution: LivekitClient.VideoPresets.h720.resolution,
                    },
                });
                
                // Handle media streams and attach them to avatar video element
                avatarMediaStream = new MediaStream();

                // Attach room event listeners
                roomEvents(e);
        
                await room.prepareConnection(sessionInfo.url, sessionInfo.access_token);

                // Connect WebSocket after room preparation
                await connectWebSocket(sessionInfo);
                await startSession(sessionInfo);
            
                // Connect to room to show avatar
                await room.connect(sessionInfo.url, sessionInfo.access_token);
            } catch (error) {
                errorContainer.classList.add("d-block");
                loadingSpinner.classList.remove("d-block");
            }
        }

        // Submit AI chat text
        async function handleChatFormSubmit(e, promptValue) {
            e.preventDefault();

            if(errorContainer.classList.contains("d-block")) {
                errorContainer.classList.remove("d-block");
            }

            if(messageContainer.classList.contains("d-block")) {
                messageContainer.classList.remove("d-block")
            }

            if(campaignName === "Unboxing2025") {
                // set input text to either prompt value or input field value
                inputText = sanitizeInput(promptValue || taskInput.value);

                if(inputText.trim() === "") {
                    return;
                }
                
                // Do not show introductory text in the chat
                if(!initialPageLoad) {
                    updateContent(messageContainer, "p", inputText);
                    messageContainer.classList.add("d-block");
                }

                initialPageLoad = false;
    
                hidePrompts(showPromptsButton, hidePromptsButton, form);
                showPromptsButton.classList.add("d-none");
            } else {
                inputText = sanitizeInput(taskInput.value);
                updateContent(messageContainer, "p", inputText);
                messageContainer.classList.add("d-block");
            }

            if(inputText.trim() === "") {
                return;
            }

            if (pendingFormRequest) return;
            pendingFormRequest = true;

            loadingSpinner.classList.add("d-block");

            try {
                // Send task
                const promptValues = await sendText(apiUrl, sessionDetails, hashIdentifier, inputText);
                const task = await sendTask(sessionDetails, promptValues);
                
                taskInput.value = "";

                if(promptValues.error || task.error) throw new Error("Something went wrong when sending task");

                if(campaignName === "Unboxing2025") {
                    updateContent(document.querySelector(".js-chat-prompt--1"), "span", promptValues.prompt1);
                    updateContent(document.querySelector(".js-chat-prompt--2"), "span", promptValues.prompt2);
                    updateContent(document.querySelector(".js-chat-prompt--3"), "span", promptValues.prompt3);
                }
            } catch (error) {
                errorContainer.classList.add("d-block");
                loadingSpinner.classList.remove("d-block");
                submitButton.removeAttribute("disabled");
            } finally {
                pendingFormRequest = false;
            }
        }

        // Handle room connection state changes
        // Connected
        function listenToConnectedRoom(e) {
            form.classList.remove("d-none");
            const hideOverlayTimeout = setTimeout(() => {
                playAvatarOverlay.classList.add("d-none");
                clearTimeout(hideOverlayTimeout);
            }, 300);

            if(campaignName === "Unboxing2025") {
                showPromptsButton.classList.remove("d-none");
                showPromptsButton.classList.add("fade-in");

                if(initialPageLoad) {
                    inputText = "Hi, write me an introduction";
                    handleChatFormSubmit(e, inputText);
                }
            } else {
                loadingSpinner.classList.remove("d-block");
                submitButton.removeAttribute("disabled");
                stopAvatarButton.setAttribute('disabled', 'true');
            }
        }        

        // Disconnect
        function handleDisconnectedRoom() {
            loadingSpinner.classList.remove("d-block");
            submitButton.removeAttribute('disabled');

            form.classList.add("d-none");
            stopAvatarButton.classList.remove("fade-in");
            stopAvatarButton.classList.add("d-none");
            updateContent(form.closest(".dm-ai-chat"), ".chat-message p", "");
            avatar.querySelector(".chat-message").classList.remove("d-block");

            if(errorContainer.classList.contains("d-block")) {
                errorContainer.classList.remove("d-block");
            }

            // Show avatar overlay
            playAvatarOverlay.classList.remove("d-none");
            const hideOverlayTimeout = setTimeout(() => {
                playAvatarOverlay.classList.remove("hide");
                playAvatarOverlay.querySelector("button").classList.remove("d-none");
                clearTimeout(hideOverlayTimeout);
            }, 300);

            // Show starting video again
            if(greetingVideo) {
                greetingVideo.classList.remove("d-none");
                greetingVideo.currentTime = 0;

                const hideOverlayTimeout = setTimeout(() => {
                    greetingVideo.classList.remove("hide");
                    clearTimeout(hideOverlayTimeout);
                }, 300);
            }

            // Hide prompts and prompts button
            if(campaignName === "Unboxing2025") {
                initialPageLoad = true;
                hidePrompts(showPromptsButton, hidePromptsButton, form);
                showPromptsButton.classList.add("hide");
                const buttonsToggleTimeout = setTimeout(() => {
                    showPromptsButton.classList.add("d-none");
                    clearTimeout(buttonsToggleTimeout);
                }, 400);
            }
        }
        
        // Handle Room events
        function roomEvents(e) {
            // Room messages 
            room.on(LivekitClient.RoomEvent.DataReceived, (message) => {
                const data = new TextDecoder().decode(message);
                const avatarEventType = JSON.parse(data);

                stopAvatarButton.classList.remove("d-none");
                stopAvatarButton.classList.add("fade-in");

                if(avatarEventType.type === "avatar_start_talking") {
                    stopAvatarButton.removeAttribute("disabled");
                    loadingSpinner.classList.remove("d-block");
                    submitButton.setAttribute("disabled", "true");
                }

                if(avatarEventType.type === "avatar_stop_talking") {
                    if(errorContainer.classList.contains("d-block")) errorContainer.classList.remove("d-block");
                    submitButton.removeAttribute("disabled");
                    stopAvatarButton.setAttribute('disabled', 'true');
                    messageContainer.classList.remove("d-block");
                    showAvatarInputField(form);

                    if(campaignName === "Unboxing2025") {
                    
                        showPromptsButton.classList.remove("d-none");
                        const buttonsToggleTimeout = setTimeout(() => {
                            showPromptsButton.classList.remove("hide");
                            clearTimeout(buttonsToggleTimeout);
                        }, 200);
                    }
                }
            });
        
            // Handle media streams and attach them to avatar video element
            room.on(LivekitClient.RoomEvent.TrackSubscribed, (track) => {
                if (track.kind === "video" || track.kind === "audio") {
                    avatarMediaStream.addTrack(track.mediaStreamTrack);
                    if (avatarMediaStream.getVideoTracks().length > 0 && avatarMediaStream.getAudioTracks().length > 0) {
                        mediaElement.srcObject = avatarMediaStream;
                    }
                }
            });
        
            // Disconnected
            room.on(LivekitClient.RoomEvent.Disconnected, (reason) => {
                closeSession(sessionDetails, mediaElement, room);
                initialPageLoad = true;
                handleDisconnectedRoom();
            });
        
            // Connected
            room.on(LivekitClient.RoomEvent.Connected, () => {
                listenToConnectedRoom(e);
            });
        }
        
        // -------- Event listeners -------- //
        // Get greeting video link and video events
        if(greetingVideo) {

            if(hashIdentifier) {
                const videoSrc = await fetchVideoLink(hashIdentifier);
                greetingVideo.src = videoSrc.link_url;
            }
            
            greetingVideo.addEventListener("playing", () => {
                playAvatarOverlay.classList.add("hide");
    
                const hideOverlayTimeout = setTimeout(() => {
                    playAvatarOverlay.classList.add("d-none");
                    clearTimeout(hideOverlayTimeout);
                }, 300);
    
                stopAvatarButton.removeAttribute("disabled");
                stopAvatarButton.classList.remove("d-none");
                stopAvatarButton.classList.add("fade-in");
    
            });
    
            greetingVideo.addEventListener("ended", async (e) => {
                greetingVideo.classList.add("hide");
    
                const hideVideoTimeout = setTimeout(() => {
                    greetingVideo.classList.add("d-none");
                    clearTimeout(hideVideoTimeout);
                }, 300);
    
                stopAvatarButton.classList.remove("fade-in");
                stopAvatarButton.setAttribute("disabled", "true");
                stopAvatarButton.classList.add("d-none");
                await handleStartAvatarSession(e);
            });
    
            greetingVideo.addEventListener("error", () => {
                const defaultVideoSrc = greetingVideo.getAttribute("data-default-video");
                greetingVideo.src = defaultVideoSrc;
            });
        }

        // Start session
        playAvatarOverlay.addEventListener("click", async (e) => {
            playAvatarOverlay.querySelector("button").classList.add("d-none");

            if(audio) {
                audio.volume = 0.06;
                audio.play();
            }

            if(greetingVideo) {
                avatar.querySelector(".js-greeting-video").play();
                window.dataLayer.push({
                    "event": "select_content",
                    "event_id": 1.7,
                    "language": language,
                    "campaign": campaignName
                });
            } else {
                await handleStartAvatarSession(e);
            }
        });

        // Submit AI chat form
        form.addEventListener("submit", (e) => handleChatFormSubmit(e));

        // Stop Avatar or skip video
        stopAvatarButton.addEventListener("click", async (e) => {
            if(greetingVideo && (greetingVideo.currentTime > 0 && !greetingVideo.paused)) {
                greetingVideo.pause();
                greetingVideo.classList.add("hide");
        
                const hideVideoTimeout = setTimeout(() => {
                    greetingVideo.classList.add("d-none");
                    clearTimeout(hideVideoTimeout);
                }, 300);
        
                stopAvatarButton.classList.remove("fade-in");
                stopAvatarButton.setAttribute("disabled", "true");
                stopAvatarButton.classList.add("d-none");
        
                window.dataLayer.push({
                    "event": "select_content",
                    "event_id": 1.6
                });
        
                await handleStartAvatarSession(e);
                return;
            }

            try {
                if (pendingFormRequest) return;
                pendingFormRequest = true;
                loadingSpinner.classList.add("d-block");

                const stopSpeak = await stopAvatar(sessionDetails, campaignName);
                if(stopSpeak.error) throw new Error("Failed to stop avatar speak");

                if(campaignName === "Unboxing2025") {
                    showPromptsButton.classList.remove("d-none");
                    const buttonsToggleTimeout = setTimeout(() => {
                        showPromptsButton.classList.remove("hide");
                        clearTimeout(buttonsToggleTimeout);
                    }, 300);
                }

                submitButton.removeAttribute("disabled");
                stopAvatarButton.setAttribute('disabled', 'true');
                showAvatarInputField(form);
                messageContainer.classList.remove("d-block");
            } catch (error) {
                errorContainer.classList.add("d-block");
            } finally {
                loadingSpinner.classList.remove("d-block");
                pendingFormRequest = false;
            }
        });

        // Ebook download
        if(document.querySelector(".js-download")) {
            document.querySelector(".js-download").addEventListener("click", () => handleDownloadButtonClick(hashIdentifier, '.dm-ai-chat__overlay', campaignName));
            // Close overlay
            document.querySelector(".js-close-overlay").addEventListener("click", () => closeOverlay('.dm-ai-chat__overlay'));
        }

        // Single prompt
        if(campaignName === "Unboxing2025") {
            // Prompts show/hide
            showPromptsButton.addEventListener("click", () => showPrompts(showPromptsButton, hidePromptsButton, form, campaignName));
            hidePromptsButton.addEventListener("click", () => hidePrompts(showPromptsButton, hidePromptsButton, form));

            prompts.forEach((item, i) => item.addEventListener("click", async (e) => {
                window.dataLayer.push({
                    "event": "select_content",
                    "event_id": 1.11,
                    "language": language,
                    "campaign": campaignName,
                    "event_status": `Prompt${i + 1}`
                });

                hidePrompts(showPromptsButton, hidePromptsButton, form);
                loadingSpinner.classList.add("d-block");
                handleChatFormSubmit(e, item.querySelector("span").innerHTML);
            }));
        }
    }
}

