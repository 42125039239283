export function handleDownloadButtonClick(clientHash, overlaySelector, campaignName) {
    const overlay = document.querySelector(`${overlaySelector}`);
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);

    if(!clientHash) {
        overlay.classList.remove("d-none");
        
        const showOverlayTimeout = setTimeout(() => {
            overlay.classList.add("show-opacity");
            clearTimeout(showOverlayTimeout);
        }, 300);

        document.querySelector("html").classList.add("overflow-hidden");

        window.dataLayer.push({
            "event": "select_content",
            "event_id": 1.8,
            "language": document.documentElement.lang,
            "campaign": campaignName,
            "event_status": "Newsletter"
        });

    } else {
        const pdfLink = document.querySelector(".dm-hero--ai").getAttribute("data-file");
        const pdfFilename = document.querySelector(".dm-hero--ai").getAttribute("data-filename");
        // Create a temporary link element
        const link = document.createElement("a");
        link.href = pdfLink;
        link.download = pdfFilename;
        link.click();

        window.dataLayer.push({
            "event": "select_content",
            "event_id": 1.8,
            "language": document.documentElement.lang,
            "campaign": campaignName,
            "event_status": "Download"
        });
    }
}

export function closeOverlay(overlaySelector) {
    const overlay = document.querySelector(`${overlaySelector}`);

    overlay.classList.remove("show-opacity");
    const hideOverlayTimeout = setTimeout(() => {
        overlay.classList.add("d-none");
        clearTimeout(hideOverlayTimeout);
    }, 300);
    
    if(overlay.querySelector("form")) {
        const overlayForm = overlay.querySelector("form");
        overlayForm.reset();
        overlayForm.querySelectorAll(".error").forEach(input => input.classList.remove("error"));
    }
    document.querySelector("html").classList.remove("overflow-hidden");
}