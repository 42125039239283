import { base } from "../avatar.mjs";

export async function stopAvatar(sessionInfo, campaignName) {

    try {
        const response = await fetch(`${base}/api/ai/heygen/interrupt-task/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                session_id: sessionInfo.session_id
            }),
        });

        const stopTaskResponse = await response.json();

        if (!response.ok) {
            throw new Error(`${stopTaskResponse.detail}` || `Looks like something went wrong with request`);
        }

        window.dataLayer.push({
            "event": "select_content",
            "event_id": 1.6,
            "language": document.documentElement.lang,
            "campaign": campaignName
        });

        return stopTaskResponse;

    } catch (error) {
        console.log(error);
        return { error: error.message };
    }
}